import { useMemo } from 'react'
import { useLanguage } from 'hooks/languages/useLanguage'
import { useSelector } from 'react-redux'
import staticLanguages from 'static/languages.json'
import { useEventPricingQuery } from './useEventQuery'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'
import { getCurrentUser } from 'redux/selectors'
import settings from 'settings'

const englishLanguage = staticLanguages.data.find(({ locale }) => locale === 'en')

export function useOrderedEventDescriptionsSelector({ event, by }) {
  const { appLanguage } = useLanguage()

  return useMemo(() => {
    let eventDescriptions = event.descriptions.filter(({ describes }) => describes === by)
    if (!eventDescriptions.length) return []

    eventDescriptions = eventDescriptions.sort((descA, descB) => descA.order - descB.order)

    const preferredEventDescriptions = eventDescriptions.filter(
      (description) => description.language_id === appLanguage.id || description.language_id === englishLanguage.id,
    )

    if (preferredEventDescriptions.length > 0) return preferredEventDescriptions
    return eventDescriptions.filter((description) => description.language_id === eventDescriptions[0].language_id)
  }, [appLanguage.id, by, event.descriptions])
}

export function useEventIsEligibleToWelcomeDiscountSelector(eventId) {
  const promoCode = settings.welcomeDiscountCode
  const currentUser = useSelector(getCurrentUser)
  const { isInvalidPromoCode } = useEventPricingQuery(eventId, { promoCode })

  return {
    canApplyWelcomeDiscount: !loadCookie(COOKIE_KEYS.AFFID) && !currentUser.partner && isInvalidPromoCode !== true,
    promoCode,
  }
}
