import { useEffect } from 'react'
import { useEventPricingQuery } from 'hooks/queries/event'
import { konstants, numbers } from '@vizeat/helpers'
import settings from 'settings'

export function useAwinTracking({ event, date, seats, bookingId, promoCode, isPartner }) {
  const shouldTrack = typeof window?.AWIN?.Tracking !== 'undefined' && settings.isAwinTrackingActive && !isPartner
  const { data: pricing, error } = useEventPricingQuery(
    event.id,
    {
      seats,
      date,
      promoCode,
      currencyIso3: konstants.DEFAULT_CURRENCY.iso_3,
    },
    { enabled: shouldTrack },
  )

  useEffect(() => {
    if (shouldTrack && bookingId && !error) {
      const amount = numbers
        .formatPriceAsFloat(pricing.payer.total_paid, pricing.payer.currency)
        .toFixed(pricing.payer.currency.decimal_point)
      const payload = {
        amount,
        channel: 'aw',
        orderRef: bookingId,
        parts: `DEFAULT:${amount}`,
        currency: pricing.payer.currency.iso_3,
        voucher: pricing.campaign?.code || '',
        test: '0',
      }

      try {
        window.AWIN.Tracking.Sale = payload
        window.AWIN.Tracking.run()
      } catch (e) {}
    }
  }, [bookingId, error, pricing.campaign?.code, pricing.payer.currency, pricing.payer.total_paid, shouldTrack])
}
